<template>
    <div id="order">
        <div class="admin_main_block">
            <div class="user_temp_title">订单列表
                <span style="color: #999;font-size: 14px;">(注：只保留最近30天的数据)</span>
            </div>
            <div class="admin_main_block_top clearfix">
                <div class="admin_main_block_left">
                    <!-- <div style="width:100px">
                        <el-select size="small" v-model="searchData.oldType" placeholder="选择新旧数据">
                            <el-option v-for="item in oldTypeList" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div> -->
                    <div style="width:170px">
                        <el-input size="small" placeholder="请输入订单号" v-model="searchData.bus_id" clearable></el-input>
                    </div>
                    <!-- <div style="width:400px">
                        <el-date-picker
                            v-model="time"
                            type="datetimerange"
                            size="small"
                            clearable
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"></el-date-picker>
                    </div> -->
                    <div style="width:160px">
                        <el-select size="small" v-model="searchData.status" clearable>
                            <el-option
                                v-for="item in statusList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-button size="small" type="primary" icon="el-icon-search" @click="search">查询</el-button>
                    </div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table size="small" :data="list" v-loading = 'loading'>
                    <el-table-column label="订单号" prop="id">
                    </el-table-column>
                    <el-table-column label="订单状态">
                        <template slot-scope="scope">
                            <span v-for="item in statusList" v-if="scope.row.status==item.value">{{item.label}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="导入方式">
                        <template slot-scope="scope">
                            <span v-if="scope.row.import_type==1">手动输入</span>
                            <span v-if="scope.row.import_type==2">模板导入</span>
                            <span v-if="scope.row.import_type==3">智能筛选</span>
                            <span v-if="scope.row.import_type==4">拼多多一键下单</span>
                            <span v-if="scope.row.import_type==5">淘宝一键下单</span>
                            <span v-if="scope.row.import_type==6">抖音一键下单</span>
                        </template>
                    </el-table-column>
                     <el-table-column label="支付时间" prop="pay_time">
                    </el-table-column>
                    <el-table-column label="包裹数" prop="pk_count">
                    </el-table-column>
                    <!-- <el-table-column label="出货时间"></el-table-column> -->
                    <el-table-column label="总费用" prop="amount">
                    </el-table-column>
                    <!-- <el-table-column label="店铺发货"></el-table-column> -->
                    <el-table-column label="财务备注" prop="remark" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="success" v-if="scope.row.status!=-1" @click="$router.push('/member/receipt?orderId='+scope.row.id+'&oldType='+searchData.oldType)">查看详情</el-button>
                            <el-button size="mini" type="danger" v-if="scope.row.status==0" @click="revocation(scope.row.id)">撤销订单</el-button>
                            <el-button size="mini" type="danger" v-if="scope.row.status==0" @click="payOrder(scope.row.id)">确认支付</el-button>
                            <el-button size="mini" type="danger" v-if="showKeyDelivery(scope.row)" @click="aKeyDelivery(scope.row.id)" :loading="loading">一键发货</el-button>
                            <el-button size="mini" type="primary" v-if="showBathDelivery(scope.row)"  @click="bathDeliver(scope.row.id)">插件发货</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="home_fy_block">
                    <el-pagination
                        v-if="total>0"
                        background
                        @current-change="handleCurrentChange"
                        :current-page="searchData.pageNo"
                        :page-size="searchData.pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            title="发货失败"
            :visible.sync="dialogVisible"
            width="30%">
            <span>{{msg}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            time: [],
            state: 1,
            statusList: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '待付款',
                    value: 0
                },
                {
                    label: '任务处理中',
                    value: 1
                },
                {
                    label: '发货中',
                    value: 2
                },
                {
                    label: '可发货',
                    value: 3
                },
                {
                    label: '发货完成',
                    value: 4
                },
                {
                    label: '撤销订单',
                    value: -1
                },
            ],
            searchData:{
                pageNo:1,
                pageSize:10,
                status:'',
                id:'',
                bus_id:"",
                startTime:'',
                endTime:'',
                oldType:1,
            },
            list:[],
            total:0,
            loading:false,//防重复点击
            dialogVisible:false,
            msg:'',
            oldTypeList: [
                { value: 1, label: '新数据' },
                { value: 2, label: '旧数据' },
            ],
        }
    },
    created(){
        var status = this.$route.query.status;
        if(status){
            this.searchData.status = Number(status);
        }
        //this.getList();
        this.getNewList();
        this.getCount();
    },
    mounted(){},
    watch: {},
    methods: {
        showKeyDelivery(row){
            if(row.shopId && (row.status==2 || (row.status==1 && row.payTime+120000<new Date()))){
                return true;
            }
            return false;
        },
        showBathDelivery(row){
           if ((row.status==2 || row.status==3 || (row.status==1 && row.payTime+120000<new Date())) && (row.belongTerrace==2 || row.belongTerrace==3) && row.importType==1){
               return true;
           }
           return false;
        },
        aKeyDelivery(id){
            this.loading = true
            this.$request.post({
                url: '/bus/order/aKeyDelivery',
                params:{
                    id
                },
                success:res => {
                    if(res.length > 0){
                        let str = []
                        res.forEach(res => {
                            str.push(res.orderNo)
                        });
                        str = str.join(',')
                        str = `以下订单发货失败:${str}`
                        this.msg = str
                        this.dialogVisible = true
                    }else{
                        this.$message.success('操作成功')
                        //this.getList()
                        this.getNewList();
                    }
                },
                finally:res => {
                    this.loading = false
                }
            })
        },
        search(){
            this.searchData.pageNo = 1;
            //this.getList();
            this.getNewList();
            this.getCount();
        },
        getList(){
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
            this.loading = true;
            this.$request.post({
                url:'/bus/order/list',
                params:this.searchData,
                success: res => {
                    this.list = res.list;
                    this.total = res.total;
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
        getNewList(){
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
             this.searchData.id=localStorage.getItem('ids')
            this.loading = true;
            this.$request.post({
                url:'/ReportApi/business/newlist',
                params:this.searchData,
                success: result =>{
                    this.list = result
                },
                finally: ()=>{
                    this.loading = false
                }
            })
        },
        getCount() {
            if(this.time && this.time.length>0){
                this.searchData.startTime = this.$time.getDate(this.time[0]);
                this.searchData.endTime = this.$time.getDate(this.time[1]);
            }else{
                this.searchData.startTime = '';
                this.searchData.endTime = '';
            }
            this.$request.post({
                url: '/ReportApi/business/listcount',
                params: this.searchData,
                success: result =>{
                    this.total = result
                },
                finally: ()=>{
                }
            })
        },
         handleCurrentChange(val){
            this.searchData.pageNo = val;
            //this.getList();
             this.getNewList();
        },
        //撤销订单
        revocation(id){
            if(this.loading) return;
            this.loading = true;
            // const loading = this.$loading();
            this.$request.post({
                url:'/ReportApi/business/cancel',
                params:{orderId:id},
                success: res => {
                    this.$message.success('撤销成功');
                    //this.getList();
                    this.getNewList();
                },
                finally: () => {
                    // this.loading = false;
                    // loading.close();
                }
            })
        },
        //支付订单
        payOrder(id){
            if(this.loading) return;
            this.loading = true;
            this.$request.post({
                url:'/ReportApi/business/pay',
                params:{orderId:id},
                success: res => {
                    this.$message.success('支付成功');
                    //this.getList();
                    this.getNewList();
                },
                finally: () => {
                    this.loading = false;
                }
            })
        },
       
    }
}
</script>

<style lang="scss" scoped>
.admin_table_main .el-button{
    margin-left: auto;
    margin-top: 2px;
}
#order{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        min-height: 855px;
        padding: 20px;
        border-radius: 4px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .admin_main_block_top{
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #efefef;
            .admin_main_block_left{
                float: left;
                div{
                    float: left;
                    margin-right: 15px;
                }
            }
        }
        .home_fy_block{
            margin-top: 30px;
            text-align: right;
            margin-right: 10px;
        }
    }
}
</style>
